import { Config } from './types';
export const baseApiUri = process.env.REACT_APP_API_URL || 'https://backoffice-ecgz.onrender.com/api/v1';

export const config: Config = {
    name: process.env.REACT_APP_COMPANY_NAME || 'CityBlock',
    logoUrl: process.env.REACT_APP_COMPANY_LOGO || 'https://formal-public-assets.s3.eu-west-1.amazonaws.com/cityblock.png',
    primaryColor: process.env.REACT_APP_PRIMARY_COLOR || '#0044cc',
    secondaryColor: process.env.REACT_APP_SECONDARY_COLOR || '#fffff',
    thirdColor: process.env.REACT_APP_THIRD_COLOR || '#fffff',
};
